import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/layouts/post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The purpose of this post is just to be used as a reference to those new to `}<a parentName="p" {...{
        "href": "https://ruby-lang.org/"
      }}>{`Ruby`}</a>{` programming who want an example for each operator. If you’re coming from another programming language, you’ll know most of these but if you’re new to Ruby, save this page as a reference until you become best friends.`}</p>
    <p>{`I’m sure you know how to use arithmetic operators `}<inlineCode parentName="p">{`+`}</inlineCode>{` addition, `}<inlineCode parentName="p">{`-`}</inlineCode>{` subtraction, `}<inlineCode parentName="p">{`*`}</inlineCode>{` multiplication, `}<inlineCode parentName="p">{`/`}</inlineCode>{` division, `}<inlineCode parentName="p">{`%`}</inlineCode>{` modulus and `}<inlineCode parentName="p">{`**`}</inlineCode>{` exponential power operators already.`}</p>
    <h2>{`Logical Operators`}</h2>
    <h3><inlineCode parentName="h3">{`a && b`}</inlineCode></h3>
    <p>{`The AND operator you’ll be familiar with from other programming languages. If both sides of the operator are true then the method returns true.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`true && true
#=> true

true && false
#=> false

true && nil
#=> nil

# && can be replaced by 'and' but don't do this.
`}</code></pre>
    <h3><inlineCode parentName="h3">{`a || b`}</inlineCode></h3>
    <p>{`Similar to the AND operator, OR compares both operands to compare truthiness.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`true || true
#=> true

true || false
#=> true

false && false
#=> false

# || can be replaced by 'or' but don't do this.
`}</code></pre>
    <h3><inlineCode parentName="h3">{`!(a && b)`}</inlineCode></h3>
    <p>{`You’ll want to use the bang operator when you want to compare the reverse state of the provided condition.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`!(true && true)
#=> false

!(true && false)
#=> true

!(false && false)
#=> true

# ! can be replaced by 'not' but don't do this.
`}</code></pre>
    <h3><inlineCode parentName="h3">{`?:`}</inlineCode>{` a.k.a Elvis`}</h3>
    <p>{`I don’t often use this operator as it confuses a lot of beginners working on your project. Check out this example to get a simple understanding:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`a = true

a ? true : false
#=> true
Now let’s mix a bit of previous knowledge with this operator:

a = true
b = false

a && b ? 'Yay! True.' : 'Sorry. False.'
#=> "Sorry, False."

a || b ? 'Yay! True.' : 'Sorry. False.'
#=> "Yay!, True."
`}</code></pre>
    <h2>{`Comparison Operators`}</h2>
    <h3><inlineCode parentName="h3">{`==`}</inlineCode></h3>
    <p>{`In almost every Ruby class you’ll find this operator. It checks if either side are equal or not.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`true == true
#=> true

true == false
#=> false

100 == 100
#=> true
`}</code></pre>
    <h3><inlineCode parentName="h3">{`!=`}</inlineCode></h3>
    <p>{`Friends with `}<inlineCode parentName="p">{`==`}</inlineCode>{` is the operator `}<inlineCode parentName="p">{`!=`}</inlineCode>{`. Which you might expect from the above examples does the reverse. If both operands are `}<strong parentName="p">{`not`}</strong>{` the same then the condition is true.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`10 != 20
#=> true

def age(dob)
  Age.new(dob)
end

puts age('23-07-2015') != 18 ? 'Have a coke.' : 'Have a beer.'
#=> "Have a coke."
`}</code></pre>
    <h3><inlineCode parentName="h3">{`===`}</inlineCode></h3>
    <p>{`Similar to ==, === checks for equality. If both operands are not of the exact same then it will be false. The examples below should clear this up.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`10 === 10
#=> true

10 === '10'
#=> false
`}</code></pre>
    <h3><inlineCode parentName="h3">{`>`}</inlineCode>{`, `}<inlineCode parentName="h3">{`>=`}</inlineCode>{`, `}<inlineCode parentName="h3">{`<`}</inlineCode>{` and `}<inlineCode parentName="h3">{`<=`}</inlineCode></h3>
    <p>{`As you’ll notice above, I compared the age of someone with a given target value. Let’s get started with a few examples below.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`10 > 5
#=> true

10 > 20
#=> false

10 >= 10
#=> true

10 >= 5
#=> true

5 < 10
#=> true

10 <= 10
#=> true

10 <= 5
#=> false
`}</code></pre>
    <h3><inlineCode parentName="h3">{`<=>`}</inlineCode></h3>
    <p>{`This is my favourite. It compares each operand and returns either -1, 0 or 1. I’ve used this so many times when building scoring algorithms.`}</p>
    <p>{`The best way to describe this is with an example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`5 <=> 10
#=> -1

10 <=> 10
#=> 0

15 <=> 10
#=> 1
`}</code></pre>
    <p>{`There are a lot more Ruby operators but I’ll post again soon covering `}<inlineCode parentName="p">{`~`}</inlineCode>{`, `}<inlineCode parentName="p">{`~=`}</inlineCode>{` and `}<inlineCode parentName="p">{`^`}</inlineCode>{` later.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      